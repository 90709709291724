@font-face {
    font-family: 'orbitronmedium';
    font-display: auto;
    src: url('../fonts/orbitron-medium/orbitron-medium-webfont.eot');
    src: url('../fonts/orbitron-medium/orbitron-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/orbitron-medium/orbitron-medium-webfont.woff2') format('woff2'),
         url('../fonts/orbitron-medium/orbitron-medium-webfont.woff') format('woff'),
         url('../fonts/orbitron-medium/orbitron-medium-webfont.ttf') format('truetype'),
         url('../fonts/orbitron-medium/orbitron-medium-webfont.svg#orbitronmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'orbitronregular';
    font-display: auto;
    src: url('../fonts/orbitron-regular/orbitron-regular-webfont.eot');
    src: url('../fonts/orbitron-regular/orbitron-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/orbitron-regular/orbitron-regular-webfont.woff2') format('woff2'),
         url('../fonts/orbitron-regular/orbitron-regular-webfont.woff') format('woff'),
         url('../fonts/orbitron-regular/orbitron-regular-webfont.ttf') format('truetype'),
         url('../fonts/orbitron-regular/orbitron-regular-webfont.svg#orbitronregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoblack';
    font-display: auto;
    src: url('../fonts/roboto-black/roboto-black-webfont.eot');
    src: url('../fonts/roboto-black/roboto-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-black/roboto-black-webfont.woff2') format('woff2'),
         url('../fonts/roboto-black/roboto-black-webfont.woff') format('woff'),
         url('../fonts/roboto-black/roboto-black-webfont.ttf') format('truetype'),
         url('../fonts/roboto-black/roboto-black-webfont.svg#robotoblack') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotobold';
    font-display: auto;
    src: url('../fonts/roboto-bold/roboto-bold-webfont.eot');
    src: url('../fonts/roboto-bold/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-bold/roboto-bold-webfont.woff2') format('woff2'),
         url('../fonts/roboto-bold/roboto-bold-webfont.woff') format('woff'),
         url('../fonts/roboto-bold/roboto-bold-webfont.ttf') format('truetype'),
         url('../fonts/roboto-bold/roboto-bold-webfont.svg#robotobold') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoitalic';
    font-display: auto;
    src: url('../fonts/roboto-italic/roboto-italic-webfont.eot');
    src: url('../fonts/roboto-italic/roboto-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-italic/roboto-italic-webfont.woff2') format('woff2'),
         url('../fonts/roboto-italic/roboto-italic-webfont.woff') format('woff'),
         url('../fonts/roboto-italic/roboto-italic-webfont.ttf') format('truetype'),
         url('../fonts/roboto-italic/roboto-italic-webfont.svg#robotoitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotomedium';
    font-display: auto;
    src: url('../fonts/roboto-medium/roboto-medium-webfont.eot');
    src: url('../fonts/roboto-medium/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-medium/roboto-medium-webfont.woff2') format('woff2'),
         url('../fonts/roboto-medium/roboto-medium-webfont.woff') format('woff'),
         url('../fonts/roboto-medium/roboto-medium-webfont.ttf') format('truetype'),
         url('../fonts/roboto-medium/roboto-medium-webfont.svg#robotomedium') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotoregular';
    font-display: auto;
    src: url('../fonts/roboto-regular/roboto-regular-webfont.eot');
    src: url('../fonts/roboto-regular/roboto-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-regular/roboto-regular-webfont.woff2') format('woff2'),
         url('../fonts/roboto-regular/roboto-regular-webfont.woff') format('woff'),
         url('../fonts/roboto-regular/roboto-regular-webfont.ttf') format('truetype'),
         url('../fonts/roboto-regular/roboto-regular-webfont.svg#robotoregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'robotomediumitalic';
    font-display: auto;
    src: url('../fonts/roboto-mediumitalic/roboto-mediumitalic-webfont.eot');
    src: url('../fonts/roboto-mediumitalic/roboto-mediumitalic-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/roboto-mediumitalic/roboto-mediumitalic-webfont.woff2') format('woff2'),
         url('../fonts/roboto-mediumitalic/roboto-mediumitalic-webfont.woff') format('woff'),
         url('../fonts/roboto-mediumitalic/roboto-mediumitalic-webfont.ttf') format('truetype'),
         url('../fonts/roboto-mediumitalic/roboto-mediumitalic-webfont.svg#robotomedium_italic') format('svg');
    font-weight: normal;
    font-style: normal;

}
