.banner__carousel{
	width: 100%;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
	overflow: hidden;
}
.banner__inner{
	width: 160%;
	max-width: 1920px;
	left: 50%;
	transform: translateX(-50%);
	position: relative;
}
.banner__indicators{
	justify-content: flex-end;
	margin: 0px 10%;
	margin-bottom: 30px;

	li{
		width: 50px;
		height: 6px;
		opacity: 0px;
		border: 2px solid #fff;
		opacity: 1;
		background-color: transparent;
		position: relative;

		&:after{
			width: 54px;
			height: 10px;
			position: absolute;
			content: '';
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			background-color: #fff;
			transition: all 0.3s linear;
		}

		&.active{
			&:after{
				width: 46px;
				height: 2px;
			}
		}
	}
}
.quem__somos__header{
	margin-bottom: 20px;
}
.titulo__secao{
	position: relative;
	padding-left: 48px;
	margin-bottom: 5px;
	font-family: 'robotoblack';
	font-size: 15px;
	line-height: 19px;
	color: #006859;

	&:before{
		content: '';
		position: absolute;
		top: 50%;
		left: 0px;
		transform: translateY(-50%);
		width: 42px;
		height: 4px;
		background-color: #006859;
	}
}
.sub__secao{
	font-family: 'orbitronregular';
	font-size: 25px;
	line-height: 29px;
	color: #181818;
}
.pesquisa{
	padding: 35px 0px;
	background-color: #006859;
	color: #fff;
}
.titulo__pesquisa{
	font-family: 'orbitronmedium';
	font-size: 29px;
	line-height: 33px;
	color: #fff;
}
.formulario__pesquisa{
	display: flex;
	width: 100%;
	align-items: center;
	height: 100%;
}
.pesquisa__input{
	width: 100%;
	background-color: darken(#006859, 10%);
	color: #fff;
	font-family: 'robotoitalic';
	font-size: 15px;
	line-height: 19px;
	height: 56px;
	padding: 18px 20px;
	border: none;

	&::placeholder{
		color: #fff;
	}
}
.pesquisa__btn{
	display: flex;
	align-items: center;
	justify-content: center;
	height: 56px;
	width: 56px;
	border: none;
	font-size: 24px;
	line-height: 24px;
	background-color: darken(#006859, 10%);
	color: #fff;

	&:focus{
		outline: none;
	}
}
.quem__somos{
	padding: 60px 0px 40px 0px;
	position: relative;
	overflow: hidden;

	&:after{
		width: 1920px;
		height: 100%;
		background-image: url('../images/bg__pagina.png');
		background-repeat: no-repeat;
		background-size: 1920px 1308px;
		background-position: center top;
		content: '';
		left: 50%;
		transform: translateX(-50%);
		top: 0px;
		position: absolute;
		z-index: -1;
	}
}
.btn__conteudo{
	width: 230px;
	height: 50px;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: #006859;
	color: #fff;
	font-family: 'robotobold';
	font-size: 14px;
	line-height: 14px;
	transition: all 0.4s linear;
	border: 2px solid #006859;

	&:hover{
		background-color: #fff;
		color: #006859;
		text-decoration: none;
	}
}
.quem__somos__btn{
	margin-bottom: 50px;
}
.quem__somos__desc{
	margin-bottom: 20px;
}
.quem__somos__img{
	margin-bottom: -50px;
	z-index: 2;
	position: relative;
}
.resultados{
	position: relative;
	padding: 100px 0px 120px 0px;
	z-index: 1;

	&:after{
		z-index: -1;
		content: '';
		top: 0;
		left: 0;
		width: 1545px;
		height: 383px;
		background-image: url('../images/bg__resultados.jpg');
		background-size: 1545px 383px;
		background-position: center top;
		background-repeat: no-repeat;
		position: absolute;
	}
}
.row__resultados{
	justify-content: flex-end;
}
.titulo__resultados{
	font-family: 'orbitronmedium';
	font-size: 27px;
	line-height: 31px;
	color: #fff;
	text-align: right;
	margin-bottom: 20px;
}
.resultados__item{
	width: 100%;
	margin-bottom: 25px;
}
.resultados__txt{
	display: flex;
	justify-content: space-between;
	color: #fff;
	margin-bottom: 5px;
}
.resultado__barra{
	width: 100%;
	height: 6px;
	background-color: #fff;
	position: relative;

	&:after{
		width: var(--porcentagem);
		top: 0;
		left: 0;
		content: '';
		position: absolute;
		height: 100%;
		background-color: #006859;
	}
}
.resultados__conteudo{
	position: relative;
	z-index: 1;

	&:before{
		width: 405px;
		height: 432px;
		content: '';
		position: absolute;
		top: -70px;
		right: calc(100% + 45px);
		z-index: 10;
		background-image: url('../images/mascote.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
	}
}
.pergunta__btn{
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: 'robotomedium';
	font-size: 14px;
	line-height: 18px;
	color: #6d6d6d;
	box-shadow: 0px 0px 5px rgba(#000, 0.5);
	background-color: #fff;
	width: 100%;
	position: relative;
	padding: 12px 30px 12px 20px;
	border: none;
	margin-bottom: 5px;

	&:focus{
		outline: none;
	}

	&:after{
		content: '';
		position: absolute;
		transition: all 0.3s linear;
		width: 2px;
		height: 10px;
		background-color: currentColor;
		top: 50%;
		transform: translateY(-50%);
		right: 16px;
	}
	&:before{
		content: '';
		position: absolute;
		transition: all 0.3s linear;
		width: 10px;
		height: 2px;
		background-color: currentColor;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
	}
	&[aria-expanded="true"] {
		background-color: #006859;
		color: #fff;

		&:after{
			height: 2px;
		}
	}
}
.pergunta__conteudo{
	padding: 8px 20px;
	font-size: 14px;
	line-height: 18px;
	text-align: justify;
}
.exames__header{
	margin-bottom: 15px;
}
.exames__desc{
	margin-bottom: 20px;
	font-size: 15px;
	line-height: 20px;
}
.exames{
	padding-bottom: 60px;
	padding-top: 30px;
}
.noticias{
	padding-bottom: 50px;
}
.noticias__conteudo{
	width: 100%;
	max-width: 860px;
	margin-right: 0px;
	margin-left: auto;
}
.noticias__controles{
	display: flex;
	a{
		display: flex;
		width: 34px;
		height: 32px;
		align-items: center;
		justify-content: center;
		background-color: #dbdbdb;
		color: #000;
		transition: all 0.3s linear;
		margin: 0px 2px;

		span{
			background-color: #000;
		}

		&:hover{
			background-color: #006859;

			span{
				background-color: #fff;
			}
		}
	}
}
.noticias__card{
	display: block;
	max-width: 270px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	&:hover{
		text-decoration: none;

		.noticias__data{
			background-color: #006859;
			color: #fff;
		}
	}
}
.noticias__img{
	position: relative;
	margin-bottom: 10px;
}
.noticias__data{
	z-index: 3;
	position: absolute;
	bottom: 0px;
	left: 0px;
	display: inline-block;
	padding: 8px 15px;
	color: #fff;
	background-color: #000;
	font-family: 'robotomediumitalic';
	transition: all 0.3s linear;
}
.noticias__categoria{
	font-family: 'robotoitalic';
	font-size: 14px;
	line-height: 18px;
	padding-bottom: 3px;
	border-bottom: 1px solid #d1d1d1;
	color: #555555;
	margin-bottom: 5px;
}
.noticias__titulo{
	font-family: 'robotobold';
	font-size: 18px;
	line-height: 22px;
	color: #181818;
}
.noticias__btn{
	text-align: right;
	padding-top: 10px;

	.btn__conteudo{
		margin-left: auto;
		margin-right: 0px;
	}
}
@include media-breakpoint-up (lg) {
	.noticias__header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		text-align: right;

		.titulo__secao{
			display: inline-block;
		}
	}
	.noticias__conteudo{
		position: relative;

		&:before{
			content: '';
			position: absolute;
			z-index: 5;
			right: calc(100% + 30px);
			top: -10px;
			width: 632px;
			height: 612px;
			background-image: var(--imagem);
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
		}
	}
}
@include media-breakpoint-down (md) {
	.titulo__pesquisa{
		margin-bottom: 20px;
	}
	.quem__somos__img{
		text-align: center;
	}
	.resultados{
		padding: 0px;

		&:after{
			display: none;
		}
	}
	.resultados__conteudo{
		padding: 100px 10px 50px 100px;
		position: relative;

		&:after{
			z-index: -1;
			content: '';
			top: 0;
			left: 0;
			width: 1545px;
			height: 100%;
			background-image: url('../images/bg__resultados.jpg');
			background-size: 1545px calc(100% - 30px);
			background-position: center top;
			background-repeat: no-repeat;
			position: absolute;
		}
		&:before{
			width: 204px;
			height: 216px;
			content: '';
			position: absolute;
			top: 50px;
			right: calc(100% - 80px);
			z-index: 10;
			background-image: url('../images/mascote.png');
			background-size: cover;
			background-repeat: no-repeat;
			background-position: center;
		}
	}
	.noticias{
		padding-bottom: 0px;
	}
	.noticias__header{
		display: flex;
		flex-direction: column;
	}
	.noticias__titulo{
		text-align: center;
		margin-bottom: 10px;

		.titulo__secao{
			display: inline-block;
		}
	}
	.noticias__controles{
		order: 2;
		justify-content: center;
		margin-bottom: 20px;
	}
	.noticias__titulo{
		order: 1;
	}
	.noticias__conteudo{
		display: flex;
		flex-direction: column;

		&:after{
			content: '';
			position: relative;
			z-index: 5;
			left: 50%;
			transform: translateX(-50%);
			top: 0px;
			width: 632px;
			height: 612px;
			background-image: var(--imagem);
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			margin-bottom: -20px;
		}
	}
	.noticias__btn{
		margin-bottom: 20px;
	}
}
@include media-breakpoint-down (sm) {
	.noticias__btn{
		.btn__conteudo{
			margin-right: auto;
			margin-left: auto;
		}
	}
}
@include media-breakpoint-down (xs) {
	.resultados__conteudo{
		padding: 100px 10px 50px 30px;

		&:before{
			display: none;
		}
	}
}
