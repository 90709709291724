#perguntas-2 {
	padding-bottom: 80px;

	h2{
		font-size: 20px;
		font-weight: 300;

		&:after{
			content:'';
			display: block;
			width: 120px;
			height:5px;
			background: #9D192B;
			margin-top:10px;
		}
	}

	.box-perguntas{
		padding: 90px 40px 30px 40px;
		background: #eeeeee;
	}

	.aprentar-pergunta{
		width: 426px;
		max-width: calc(100% - 15px);
		margin-bottom: -90px;
		position: relative;
		z-index: 2;
		margin-left: auto;
		margin-right: 15px;

		&:before{
			content:'';
			display: block;
			padding-bottom: percentage(264/426);
			background: url(../images/apresentar-pergunta.png);
		}
	}

	.box-perguntas-content{
		max-height: 500px;
		overflow: auto;
		padding-right: 20px;

		&::-webkit-scrollbar {
			width: 7px;
		  	border-radius: 10px;
		}
		/* Track */
		&::-webkit-scrollbar-track {
		  	background: #FFF;
		  	border-radius: 10px;
		}

		/* Handle */
		&::-webkit-scrollbar-thumb {
		  	background: #932025;
		  	border-radius:10px;
		}

		/* Handle on hover */
		&::-webkit-scrollbar-thumb:hover {
		  	background: darken(#932025, 10%);
		}
	}
}
.pergunta__custom{
	display: flex;
	justify-content: space-between;
	align-items: center;
	font-family: 'robotomedium';
	font-size: 14px;
	line-height: 18px;
	color: #6d6d6d;
	box-shadow: 0px 0px 5px rgba(#000, 0.5);
	background-color: #fff;
	width: 100%;
	position: relative;
	padding: 12px 30px 12px 20px;
	border: none;
	margin-bottom: 5px;
	cursor: pointer;

	&:focus{
		outline: none;
	}

	&:after{
		content: '';
		position: absolute;
		transition: all 0.3s linear;
		width: 2px;
		height: 10px;
		background-color: currentColor;
		top: 50%;
		transform: translateY(-50%);
		right: 16px;
	}
	&:before{
		content: '';
		position: absolute;
		transition: all 0.3s linear;
		width: 10px;
		height: 2px;
		background-color: currentColor;
		top: 50%;
		transform: translateY(-50%);
		right: 12px;
	}
	&[aria-expanded="true"] {
		background-color: #006859;
		color: #fff;

		&:after{
			height: 2px;
		}
	}
}
.resposta__custom{
	padding: 8px 20px;
	font-size: 14px;
	line-height: 18px;
	text-align: justify;
}
.pergunta-faq__custom{
	margin-bottom: 20px;
}
