.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
}
.topo{
	.rodape__sociais{
		justify-content: center;
		a{
			width: 24px;
			height: 24px;
		}
	}
}
@include media-breakpoint-up (lg) {
	.topo__main{
		display: flex;
		flex-direction: column;
		border-bottom: 1px solid #dbdbdb;
	}
	.segura__cima{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.topo__cima{
		color: #fff;
		background-color: #000;
		padding: 5px 0px;
		font-family: 'robotomedium';
		font-size: 14px;
		line-height: 18px;
		border-bottom: 4px solid #006859;
	}
	.main__menu{
		display: flex;
	}
	.menu__link{
		padding: 5px;
		display: block;
		margin: 0px 15px;
		transition: all 0.3s linear;
		position: relative;

		&:after{
			top: calc(100% + 5px);
			left: 0;
			width: 100%;
			height: 4px;
			background-color: #88dd5c;
			transition: all 0.3s linear;
			opacity: 0;
			content: '';
			position: absolute;
			z-index: 1;
		}

		&:hover{
			color: #88dd5c;
			text-decoration: none;

			&:after{
				opacity: 1;
			}
		}
	}
	.menu__item--active{
		.menu__link{
			color: #88dd5c;

			&:after{
				opacity: 1;
			}
		}
	}
	.topo__btn{
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: #006859;
		color: #fff;
		width: 230px;
		height: 50px;
		margin: 0px 5px;
		font-family: 'robotobold';
		font-size: 14px;
		line-height: 14px;
		border: 2px solid #006859;
		transition: all 0.3s linear;

		&:hover{
			background-color: #fff;
			color: #006859;
			text-decoration: none;
		}
	}
	.topo__logo,
	.topo__mbl{
		display: none;
	}
	.main__btns,
	.segura__baixo{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.topo__baixo{
		padding: 20px 0px;
		background-color: #fff;
	}
	.topo__telefone{
		display: flex;
	}
	.telefone__span{
		span{
			margin-right: 5px;
		}
	}
}
@include media-breakpoint-only (lg) {
	.main__logo{
		display: block;
		margin-right: 30px;
	}
	.topo__btn{
		width: 200px;
	}
}
@include media-breakpoint-down (md) {
	.topo__main{
		width: 250px;
		height: 100%;
		position: fixed;
		top: 0px;
		left: -250px;
		transition: all 0.4s linear;
		z-index: 1000;
		background-color: #fff;

		&.topo__main--shown{
			left: 0px;
		}
	}
	.main__logo{
		display: none;
	}
	.topo__logo{
		display: block;
		padding: 5px;
		margin-bottom: 10px;
		order: 1;
	}
	.topo__telefone{
		order: 3;
	}
	.main__menu{
		order: 2;
		border-top: 1px solid #454545;
		margin-bottom: 20px;
	}
	.menu__link{
		display: block;
		padding: 10px;
		font-family: 'robotobold';
		border-bottom: 1px solid #88dd5c;
		background-color: #fff;
		color: #88dd5c;
		transition: all 0.4s linear;

		&:hover{
			background-color: #88dd5c;
			color: #fff;
			text-decoration: none;
		}
	}
	.segura__cima{
		padding: 0px;
		display: flex;
		flex-direction: column;
	}
	.segura__baixo{
		padding: 0px;
	}
	.menu__item--active{
		.menu__link{
			background-color: #88dd5c;
			color: #fff;
		}
	}
	.topo__telefone{
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 20px;

		.barrinha{
			display: none;
		}
	}
	.telefone__span{
		display: flex;
		flex-direction: column;

		span{
			font-size: 20px;
			margin-bottom: 5px;

			&:last-child{
				display: none;
			}
		}
	}
	.topo__btn{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 250px;
		height: 60px;
		background-color: #006859;
		color: #fff;
		border-bottom: 1px solid rgba(#000, 0.4);
		transition: all 0.3s linear;

		&:hover{
			color: #fff;
			text-decoration: none;
		}
	}
	.bg__menu{
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.8);
		animation: fadeIn 0.6s linear;

		&.hide{
			animation: fadeOut 0.6s linear;
		}
	}
	.topo__mbl{
		padding: 10px 0px;
		border-bottom: 1px solid #dbdbdb;
	}
	.segura__mbl{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.mbl__botao{
		border: none;
		background-color: transparent;
		color: #000;
		font-size: 30px;

		&:focus{
			outline: none;
		}
	}
}
@include media-breakpoint-down (sm) {
	.mbl__logo{
		display: block;
		max-width: 280px;
	}
}
@include media-breakpoint-down (xs) {
	.mbl__logo{
		display: block;
		max-width: 240px;
		margin-right: 20px;
	}
}
