.agendamento__interna__conteudo{
	padding: 60px 0px 62px 0px;
	max-width: 965px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;

	.exame__interna__titulo{
		font-size: 29px;
		line-height: 33px;
	}
	.exame__interna__desc{
		text-align: center;
	}
}
.obrigatorio{
	padding-top: 10px;
	margin-bottom: 48px;
	font-family: 'robotobold';
	font-size: 15px;
	line-height: 19px;
	color: lighten(#85eeff, 10%);
	text-align: center;
}
.aonde{
	display: flex;
	justify-content: center;
	margin-bottom: 35px;

	@include media-breakpoint-down (md) {
		flex-direction: column;
		align-items: center;
	}

	.form__check{
		margin: 0px 5px;
	}
	span{
		font-family: 'robotobold';
		font-size: 15px;
		line-height: 19px;
		color: #000;

		@include media-breakpoint-down (md) {
			margin-bottom: 5px;
		}
	}
}
.form__agendamento{
	.col-lg-4{
		padding: 0px 4px;
	}
	.col-lg-12{
		padding: 0px 4px;
	}
}
.agendamento__btn{
	display: flex;
	justify-content: center;
	padding-top: 20px;

	.exame__form__btn{
		width: 230px;
		height: 50px;
		background-color: #85eeff;
		color: #fff;
		font-family: 'robotobold';
		font-size: 14px;
		line-height: 14px;
		border: none;
		margin: 0px 3px;
		border: 2px solid #85eeff;
		transition: all 0.3s linear;

		&:hover{
			background-color: #fff;
			color: #85eeff;
			text-decoration: none;
		}

		&:focus{
			outline: none;
		}
	}
}
