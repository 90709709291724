.perguntas-frequentes#perguntas-1{
	padding-bottom: 80px;

	.breadcrump__interna{
		margin-bottom: 50px;
	}

	.pergunta-faq{
		padding:15px 0 15px 45px;
		min-height: 50px;
		border-bottom:2px dashed rgba(#000,0.2);
		position: relative;

		.pergunta{
			font-size: 20px;
			line-height: 1.4;
			transition:all 0.3s linear;
		}

		.pergunta:before{
			content: fa-content($fa-var-caret-right);
			font-family: 'Font Awesome 5 Free';
			font-weight: 900;
			width: 20px;
			height: 20px;
			border:2px solid currentcolor;
			display: flex;
			align-items:center;
			justify-content:center;
			border-radius:100%;
			position: absolute;
			left: 0;
			top: calc(15px + 0.2em);
			transition:all 0.3s linear;
		}

		.pergunta[aria-expanded="false"]{
			cursor: pointer
		}

		.pergunta[aria-expanded="true"]{
			color: #0071BD;

			&:before{
				transform: rotate(90deg);
			}
		}
	}
}
