.breadcrump__interna{
	padding: 12px 0px;

	.container{
		display: flex;
		justify-content: flex-end;
	}
}
.exame__interna__conteudo{
	padding: 50px 0px 80px 0px;
}
.exame__interna__titulo{
	text-align: center;
	font-family: 'orbitronregular';
	font-size: 29px;
	line-height: 33px;
	margin-bottom: 10px;
	color: #181818;
}
.exame__interna__desc{
	width: 100%;
	max-width: 930px;
	margin-right: auto;
	margin-left: auto;
	margin-bottom: 10px;
}
.exame__form{
	width: 100%;
	max-width: 730px;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	align-items: center;
	margin-bottom: 25px;
}
.exame__input{
	width: 100%;
	height: auto;
	background-color: #ededed;
	color: #181818;
	font-size: 15px;
	line-height: 19px;
	padding: 15px 26px;
	border: none;
	font-family: 'robotomedium';

	&::placeholder{
		color: #181818;
	}

	&:focus{
		outline: none;
	}
}
.exame__pesquisa__btn{
	width: 49px;
	height: 49px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #ededed;
	color: #000;
	border: none;

	&:focus{
		outline: none;
	}
}
.alerta__exames{
	font-family: 'robotobold';
	font-size: 15px;
	line-height: 19px;
	color: #006859;
	margin-bottom: 20px;
	text-align: center;
	margin-right: auto;
	margin-left: auto;
}
.exames__filtro{
	display: flex;
	justify-content: center;
	flex-wrap: wrap;
	padding-bottom: 25px;
	border-bottom: 1px solid #dbdbdb;
	margin-bottom: 25px;
}
.exames__link{
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 27px;
	width: 27px;
	height: 27px;
	font-family: 'robotobold';
	font-size: 15px;
	line-height: 15px;
	transition: all 0.3s linear;

	&:hover{
		background-color: #006859;
		color: #fff;
		text-decoration: none;
	}

	&:nth-child(1) {
		width: 68px;
		height: 27px;
	}
	&:nth-child(2) {
		width: 38px;
		height: 27px;
	}
	&.exame__link--active{
		background-color: #006859;
		color: #fff;
	}
}
.titulo__exame{
	font-family: 'orbitronregular';
	font-size: 29px;
	line-height: 33px;
	color: #181818;
	text-align: center;
	margin-bottom: 30px;
}
.exame__card{
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 20px;
	background-color: #ededed;
	color: #181818;
	margin: 5px;
	transition: all 0.3s linear;
	font-family: 'robotobold';

	.icon__mask{
		transition: all 0.3s linear;
		background-color: #181818;
		min-width: 17px;
	}

	&:hover{
		background-color: #006859;
		color: #fff;
		text-decoration: none;

		.icon__mask{
			background-color: #fff;
		}
	}
}
.exames__listagem{
	display: flex;
	flex-wrap: wrap;
}
.titulo__especificacoes{
	font-family: 'robotobold';
	font-size: 15px;
	line-height: 19px;
	color: #006859;
	margin-bottom: 10px;
	padding-top: 20px;
	text-align: center;
}
.especificacoes__tabela{
	max-width: 800px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	display: flex;
	justify-content: center;
	margin-bottom: 40px;
}
.especificacoes__item{
	display: flex;
	flex-direction: column;
}
.especificacoes__titulo{
	font-family: 'robotobold';
	font-size: 15px;
	line-height: 19px;
	color: #fff;
	background-color: #202020;
	padding: 10px 12px;
}
.especificacoes__info{
	padding: 10px 12px;
	font-family: 'robotobold';
	font-size: 15px;
	line-height: 19px;
	color: #181818;
}
.form__exames{
	padding-top: 45px;
	border-top: 1px solid #dbdbdb;
}
.exame__form__titulo{
	text-align: center;
	margin-bottom: 20px;
}
.segura__form{
	width: 100%;
	max-width: 930px;
	margin-right: auto;
	margin-left: auto;
}
.form__check{
	display: flex;
	justify-content: center;
	color: #181818;
	align-items: center;

	input{
		position: fixed;
		left: -100vw;

		&:checked{
			& ~ .label__check{
				&:after{
					opacity: 1;
				}
			}
		}
	}
}
.label__check{
	margin-bottom: 0px;
	position: relative;
	padding-left: 22px;
	cursor: pointer;

	&:before{
		width: 17px;
		height: 17px;
		border-radius: 50%;
		border: 2px solid #181818;
		content: '';
		position: absolute;
		left: 0px;
		top: 50%;
		transform: translateY(-50%);
	}
	&:after{
		width: 7px;
		height: 7px;
		top: 50%;
		transform: translateY(-50%);
		left: 5px;
		background-color: #000;
		transition: all 0.3s linear;
		content: '';
		position: absolute;
		border-radius: 50%;
		opacity: 0;
	}
}
.exames__botoes{
	display: flex;
	justify-content: center;
	margin-bottom: 30px;

	.exame__form__btn{
		width: 230px;
		height: 50px;
		background-color: #006859;
		color: #fff;
		font-family: 'robotobold';
		font-size: 14px;
		line-height: 14px;
		border: none;
		margin: 0px 3px;
		border: 2px solid #006859;
		transition: all 0.3s linear;

		&:hover{
			background-color: #fff;
			color: #006859;
			text-decoration: none;
		}

		&:focus{
			outline: none;
		}
	}
	.exame__whats{
		width: 270px;
		height: 50px;
		background-color: #006859;
		color: #fff;
		font-family: 'robotobold';
		font-size: 14px;
		line-height: 14px;
		border: none;
		margin: 0px 3px;
		border: 2px solid #006859;
		transition: all 0.3s linear;

		&:hover{
			background-color: #fff;
			color: #006859;
			text-decoration: none;
		}

		&:focus{
			outline: none;
		}
	}
}
.segura__form{
	max-width: 930px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
@include media-breakpoint-up (lg) {
	.exame__card{
		max-width: 48%;
	}
	.especificacoes__item{
		max-width: 150px;
		width: 100%;

		&:last-child{
			max-width: 350px;
			width: 100%;
		}
	}
	.whatsapp__form{
		max-width: 460px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
}
@include media-breakpoint-down (md) {
	.especificacoes__tabela{
		flex-direction: column;
		text-align: center;
	}
}
@include media-breakpoint-down (sm) {
	.exames__botoes{
		display: flex;
		flex-direction: column;
		align-items: center;

		.exame__form__btn{
			margin: 5px 0px;
		}
		.exame__whats{
			margin: 5px 0px;
		}
	}
}
@include media-breakpoint-down (xs) {
	.exame__card{
		padding: 10px;
	}
}
