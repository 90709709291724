.rodape__conteudo{
	padding: 70px 0px 0px 0px;
	background-color: #181818;
	color: #fff;
}
.logo__rodape{
	text-align: center;
	margin-bottom: 30px;
}
.box__rodape{
	display: flex;
	border-top: 1px solid #282828;
	border-bottom: 1px solid #282828;
	padding: 12px 0px;
}
.rodape__desc{
	color: #b5b5b5;
}
.rodape__sociais{
	display: flex;
	// padding-left: 20px;
	// margin-left: 20px;
	// border-left: 1px solid #fff;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 38px;
		height: 38px;
		border-radius: 50%;
		border: 1px solid #fff;
		transition: all 0.3s linear;
		margin: 0px 2px;
		font-size: 12px;
		line-height: 12px;

		&:hover{
			background-color: #fff;
			color: #006859;
			text-decoration: none;
		}
	}
}
.rodape__contato{
	display: flex;
	justify-content: space-between;
	padding: 40px 0px 50px 0px;
}
.contato__coluna{
	max-width: 300px;
	width: 100%;
	display: flex;
	font-family: 'robotomedium';
	font-size: 14px;
	line-height: 18px;
}
.contato__icone{
	margin-right: 10px;
}
.box__unidades{
	padding: 20px 0;

	.titulo{
		font-family: 'robotobold';
		font-size: 20px;
		line-height: 24px;
		text-align: center;
		margin-bottom: 25px;
	}

	.unidade__coluna{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-bottom: 20px;

		.unidade__icone{
			margin-right: 15px;
		}

		.unidade__conteudo{
			font-family: 'robotoregular';
			font-size: 14px;
			line-height: 18px;

			.ident{
				font-family: 'robotomedium';
				font-size: 16px;
				line-height: 20px;
				margin-right: 10px;
			}
		}
	}

	.segura__mapa{
		.mapa__contato{
			height: 200px;
		}
	}
}
.rodape__creditos{
	padding: 20px 0px;
	background-color: #006859;
	color: #fff;
	text-align: center;
}
.segura__creditos{
	display: flex;
	flex-direction: column;
	align-items: center;
	font-size: 11px;
	line-height: 15px;
}
.gv8__box{
	display: flex;
	align-items: center;
	justify-content: center;
	padding-top: 5px;
}
.gv8__logo{
	margin-left: 10px;
}
@include media-breakpoint-down (md) {
	.box__rodape{
		flex-direction: column;
	}
	.rodape__sociais{
		margin-top: 10px;
		padding-top: 10px;
		border-top: 1px solid #282828;
		border-left: 0px;
		margin-left: 0px;
		padding-left: 0px;
		justify-content: center;
	}
	.rodape__contato{
		flex-direction: column;
		align-items: center;
	}
	.contato__coluna{
		width: 100%;
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-bottom: 20px;
	}
	.contato__icone{
		margin-right: 0px;
		margin-bottom: 5px;
	}
	.exame__btn{
		margin-bottom: 30px;
	}
}
@include media-breakpoint-down (xs) {
	.contato__conteudo{
		flex-direction: column;
		display: flex;

		.barrinha{
			display: none;
		}
	}
	.contato__coluna{
		&:first-child{
			.contato__conteudo{
				span{
					&:first-child{
						display: none;
					}
				}
			}
		}
		&:last-child{
			.contato__conteudo{
				span{
					&:first-child{
						display: none;
					}
				}
			}
		}
	}
}
